body{
    background-color:rgb(206, 206, 206)!important;
}

.container-whitepaper{
	font-family: 'Times New Roman';
	/*text-transform: uppercase;*/
	color:#000000;
    background-color:rgb(206, 206, 206)!important;
}

.whitepaper-title{
	font-size:25px;
	font-weight: bold;
	margin-top:45px;
}

.main-container-wp{
	background-color:#292a2b;
	padding-top:65px;
}

.container-citation{
	font-family: "Roman Gideon";
	margin-top:170px;
	text-align:justify;
	color:#000000;
	text-shadow: 0 0 0;
}

.logo-matrixed{
	margin-bottom: 100px;
	text-align:center;
}

.whitepaper-p{
    margin-top:55px;
	font-size:20px!important;
}

.return-btn{
	margin-bottom:21px;
}

.wrapper{
	height:500px!important;
}