.navbar-sigma {
    margin-left:25px;
    font-size:25px;
}

.navbar-header{
    position: fixed!important;
    width:100%;
    /*box-shadow: -10px 10px 20px black;*/
    padding-top:25px;
    padding-bottom:25px;
    z-index: 5;
    filter: drop-shadow(0 10px 15px rgb(0, 0, 0));
}

.navbar-right{
    margin-right: 100px;
}

.navbar-right li{
    margin-right: 45px;
}

.navbar-right a{
    color: white;
    /*text-transform: uppercase;*/
}

.nav-link{
    margin-left:  5px;
}