@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gideon+Roman&display=swap');

@font-face{
	font-family: "Computer Aid";
	src: url("./assets/fonts/ComputerAid.ttf")
}

@font-face{
	font-family: "Deltaray";
	src: url("./assets/fonts/deltaray.ttf")
}

body{
	/*background-color:#2f3136;
	background-color:#292a2b;*/
	background-color:#1a1e21;
	color:white;
	margin-bottom:0;
	padding-bottom:0;
}

.navbar-degrad {
    background: linear-gradient(to right, #250032, #12453d);
 }
