.footer-bloc{
    height:60px;
}

.footer-text{
    margin: right 25px, important !important;
}

.pull-right{
    margin-right: 25px!important;
}